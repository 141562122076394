import Rails from '@rails/ujs';
import '../detect_timezone';

Rails.start();

import * as bootstrap from 'bootstrap'
import {Popover} from 'bootstrap';

window.addEventListener('load', function () {
    // enable tooltip
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
    const tooltipList = [...tooltipTriggerList].map((tooltipTriggerEl) => new bootstrap.Tooltip(tooltipTriggerEl))
});
